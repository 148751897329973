
let codeList =[];
let codeToName ={};
let nameToCode ={};

/*const getPath = function (dir) {
  return path.join(__dirname, dir)
}*/


/**
 *
 * @param fileName
 */
export function init(fileName){
  const datas = require('./data/'+fileName+'.json');
  codeList = datas.dataList;
  codeToName = datas.dataMap;
  for (let key in codeToName){
    nameToCode[codeToName[key]] = key ;
  }
}

/**
 * 根据多个code值和定义的分割符得到相应的code值的名称
 * @param codes 1220000,130000,1210010
 * @param separators 如果不传默认为"/"
 */
export function  getCodeNames(codes,separators,isline){
  if(!codes&&isline){
      return '--'
  }
  if(codes){
    if(typeof codes !== 'string'){
      codes = codes.toString();
    }
    // let getCodes = codes.toString();
    let split = codes.split(",");
    let names = '';
    if(!separators){
      separators=' / ';
    }
    for (let i =0,l = split.length;i<l;i++){
      if(i==l-1){
        names += codeToName[split[i]];
      }else {
        names += codeToName[split[i]]+separators;
      }
    }
    return names;
  }else{
    return codes;
  }

}

/**
 * 根据多个名称和定义的分割符得到相应的名称的code值
 * @param codes 北京市,北京市,昌平区
 * @param separators 如果不传默认为"/"
 */
export function  getNameCodes(names,separators){
  let split = names.split(",");
  let codes = '';
  if(!separators){
    separators='/';
  }
  for (let i =0,l = split.length;i<l;i++){
    if(i==l-1){
      codes += nameToCode[split[i]];
    }else {
      codes += nameToCode[split[i]]+separators;
    }
  }
  return codes;
}
export { codeList, codeToName, nameToCode}
